import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
import { MyserviceService } from '../myservice.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpResponse } from '@angular/common/http';

@Component({
	selector: 'app-management-team-dialog',
	templateUrl: './management-team-dialog.component.html',
	styleUrls: ['./management-team-dialog.component.scss']
})
export class ManagementTeamDialogComponent implements OnInit {
	createdForm: FormGroup;
	profileMode: any;
	_userid: any;
	meName: any;
	myUser: any;
	dropDownReportTo: any;
	showHideAdd: boolean = false;
	is_edit: boolean = false;
	showHideProfileStatus: boolean = false;
	_profileStatus = ['Registered', 'Voice Test', 'Voice Reject', 'Voice Approved', 'Training', 'In Training', 'Training Reject', 'Training Complete', 'Document Pending', 'Project Pending', 'On Board', 'OJT', 'Production', 'Project'];
	work_option = ['WFO', 'WFH'];
	profileStatus: any;
	_role = [{ name: 'Tele Caller', role: 'user' }, { name: 'Non Tele Caller', role: 'non-user' }, { name: 'Admin', role: 'admin' }, { name: 'Data Manager', role: 'data-manager' }, { name: 'Super Admin', role: 'superadmin' }]
	passingMessage: any;
	showHidePassingMessage: boolean = false;
	_color: any = "";

	currentFileobj: any = {};
	currentLoader: boolean = false;
	showHideSubmitUpload: boolean = false;
	secondaryAgents: any = [];
	previous_email: any;
	api_end_point: any;
	region: any = {
		'+91': [{
			"key": "AN",
			"name": "Andaman and Nicobar Islands"
			},
			{
			"key": "AP",
			"name": "Andhra Pradesh"
			},
			{
			"key": "AR",
			"name": "Arunachal Pradesh"
			},
			{
			"key": "AS",
			"name": "Assam"
			},
			{
			"key": "BR",
			"name": "Bihar"
			},
			{
			"key": "CG",
			"name": "Chandigarh"
			},
			{
			"key": "CH",
			"name": "Chhattisgarh"
			},
			{
			"key": "DH",
			"name": "Dadra and Nagar Haveli"
			},
			{
			"key": "DD",
			"name": "Daman and Diu"
			},
			{
			"key": "DL",
			"name": "Delhi"
			},
			{
			"key": "GA",
			"name": "Goa"
			},
			{
			"key": "GJ",
			"name": "Gujarat"
			},
			{
			"key": "HR",
			"name": "Haryana"
			},
			{
			"key": "HP",
			"name": "Himachal Pradesh"
			},
			{
			"key": "JK",
			"name": "Jammu and Kashmir"
			},
			{
			"key": "JH",
			"name": "Jharkhand"
			},
			{
			"key": "KA",
			"name": "Karnataka"
			},
			{
			"key": "KL",
			"name": "Kerala"
			},
			{
			"key": "LD",
			"name": "Lakshadweep"
			},
			{
			"key": "MP",
			"name": "Madhya Pradesh"
			},
			{
			"key": "MH",
			"name": "Maharashtra"
			},
			{
			"key": "MN",
			"name": "Manipur"
			},
			{
			"key": "ML",
			"name": "Meghalaya"
			},
			{
			"key": "MZ",
			"name": "Mizoram"
			},
			{
			"key": "NL",
			"name": "Nagaland"
			},
			{
			"key": "OR",
			"name": "Odisha"
			},
			{
			"key": "PY",
			"name": "Puducherry"
			},
			{
			"key": "PB",
			"name": "Punjab"
			},
			{
			"key": "RJ",
			"name": "Rajasthan"
			},
			{
			"key": "SK",
			"name": "Sikkim"
			},
			{
			"key": "TN",
			"name": "Tamil Nadu"
			},
			{
			"key": "TS",
			"name": "Telangana"
			},
			{
			"key": "TR",
			"name": "Tripura"
			},
			{
			"key": "UK",
			"name": "Uttar Pradesh"
			},
			{
			"key": "UP",
			"name": "Uttarakhand"
			},
			{
			"key": "WB",
			"name": "West Bengal"
			}],
			'+1': [
				{
					"name": "Alabama",
					"key": "AL"
				},
				{
					"name": "Alaska",
					"key": "AK"
				},
				{
					"name": "American Samoa",
					"key": "AS"
				},
				{
					"name": "Arizona",
					"key": "AZ"
				},
				{
					"name": "Arkansas",
					"key": "AR"
				},
				{
					"name": "California",
					"key": "CA"
				},
				{
					"name": "Colorado",
					"key": "CO"
				},
				{
					"name": "Connecticut",
					"key": "CT"
				},
				{
					"name": "Delaware",
					"key": "DE"
				},
				{
					"name": "District Of Columbia",
					"key": "DC"
				},
				{
					"name": "Federated States Of Micronesia",
					"key": "FM"
				},
				{
					"name": "Florida",
					"key": "FL"
				},
				{
					"name": "Georgia",
					"key": "GA"
				},
				{
					"name": "Guam",
					"key": "GU"
				},
				{
					"name": "Hawaii",
					"key": "HI"
				},
				{
					"name": "Idaho",
					"key": "ID"
				},
				{
					"name": "Illinois",
					"key": "IL"
				},
				{
					"name": "Indiana",
					"key": "IN"
				},
				{
					"name": "Iowa",
					"key": "IA"
				},
				{
					"name": "Kansas",
					"key": "KS"
				},
				{
					"name": "Kentucky",
					"key": "KY"
				},
				{
					"name": "Louisiana",
					"key": "LA"
				},
				{
					"name": "Maine",
					"key": "ME"
				},
				{
					"name": "Marshall Islands",
					"key": "MH"
				},
				{
					"name": "Maryland",
					"key": "MD"
				},
				{
					"name": "Massachusetts",
					"key": "MA"
				},
				{
					"name": "Michigan",
					"key": "MI"
				},
				{
					"name": "Minnesota",
					"key": "MN"
				},
				{
					"name": "Mississippi",
					"key": "MS"
				},
				{
					"name": "Missouri",
					"key": "MO"
				},
				{
					"name": "Montana",
					"key": "MT"
				},
				{
					"name": "Nebraska",
					"key": "NE"
				},
				{
					"name": "Nevada",
					"key": "NV"
				},
				{
					"name": "New Hampshire",
					"key": "NH"
				},
				{
					"name": "New Jersey",
					"key": "NJ"
				},
				{
					"name": "New Mexico",
					"key": "NM"
				},
				{
					"name": "New York",
					"key": "NY"
				},
				{
					"name": "North Carolina",
					"key": "NC"
				},
				{
					"name": "North Dakota",
					"key": "ND"
				},
				{
					"name": "Northern Mariana Islands",
					"key": "MP"
				},
				{
					"name": "Ohio",
					"key": "OH"
				},
				{
					"name": "Oklahoma",
					"key": "OK"
				},
				{
					"name": "Oregon",
					"key": "OR"
				},
				{
					"name": "Palau",
					"key": "PW"
				},
				{
					"name": "Pennsylvania",
					"key": "PA"
				},
				{
					"name": "Puerto Rico",
					"key": "PR"
				},
				{
					"name": "Rhode Island",
					"key": "RI"
				},
				{
					"name": "South Carolina",
					"key": "SC"
				},
				{
					"name": "South Dakota",
					"key": "SD"
				},
				{
					"name": "Tennessee",
					"key": "TN"
				},
				{
					"name": "Texas",
					"key": "TX"
				},
				{
					"name": "Utah",
					"key": "UT"
				},
				{
					"name": "Vermont",
					"key": "VT"
				},
				{
					"name": "Virgin Islands",
					"key": "VI"
				},
				{
					"name": "Virginia",
					"key": "VA"
				},
				{
					"name": "Washington",
					"key": "WA"
				},
				{
					"name": "West Virginia",
					"key": "WV"
				},
				{
					"name": "Wisconsin",
					"key": "WI"
				},
				{
					"name": "Wyoming",
					"key": "WY"
				}
			]
	}
	// region: any = {
	// 	'+91': [
	// 		"Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh", "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jammu and Kashmir", "Jharkhand", "Karnataka", "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura", "Uttarakhand", "Uttar Pradesh", "West Bengal", "Andaman and Nicobar Islands", "Chandigarh", "Dadra and Nagar Haveli", "Daman and Diu", "Delhi", "Lakshadweep", "Puducherry"
	// 	],
	// 	'+1': ['Alabama', 'Alaska', 'American Samoa', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'District of Columbia', 'Federated States of Micronesia', 'Florida', 'Georgia', 'Guam', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Marshall Islands', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Northern Mariana Islands', 'Ohio', 'Oklahoma', 'Oregon', 'Palau', 'Pennsylvania', 'Puerto Rico', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virgin Island', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming']

	// }
	constructor(private myService: MyserviceService, public dialogRef: MatDialogRef<ManagementTeamDialogComponent>, @Inject(MAT_DIALOG_DATA) public viewManagementTeamData, public formbuilder: FormBuilder, private API: ApiService, private service: MyserviceService, private _snackBar: MatSnackBar) {
		//console.log(viewManagementTeamData)
		this.createdForm = this.formbuilder.group({
			name: ['', Validators.required],
			countryCode: ['+91'],
			email: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$")]],
			mobileNo: ['', [Validators.required, Validators.pattern("[0-9 ]{10}")]],
			role: [''],
			reportTo: [''],
			profileStatus: [''],
			"fileSource": [''],
			"file": [''],
			jobProfile: [''],
			secExec: [''],
			address2: [''],
			work_option: ['']
		})
		if (viewManagementTeamData && viewManagementTeamData._group && viewManagementTeamData._group.length) {
			this.secondaryAgents = viewManagementTeamData._group[0].groups.filter(x => x.role == "user")
			if (viewManagementTeamData._element && viewManagementTeamData._element.id) {
				this.secondaryAgents = this.secondaryAgents.filter(x => x.id != viewManagementTeamData._element.id);
			}
		}
	}

	ngOnInit() {
		this.myUser = this.myService.get_loggedin_user();

		this.api_end_point = this.myService.get_API_End_Point();
		this.dropDownReportTo = this.viewManagementTeamData._group;
		// console.log('dropdown data', this.dropDownReportTo);
		// console.log('logged in user', this.myUser);
		this._userid = this.myUser.id;
		if (this.viewManagementTeamData.forNew == "add") {
			this.profileMode = this.viewManagementTeamData && this.viewManagementTeamData.upload ? "Upload Profiles" : "Add Profile";
			this._userid = this.viewManagementTeamData._id;
			this.meName = this.viewManagementTeamData.meName;
			this.showHideAdd = true;
			this.showHideProfileStatus = false;
		}
		else if (this.viewManagementTeamData.forNew == "workoption") {
			this.profileMode = this.viewManagementTeamData && this.viewManagementTeamData.upload ? "Work Mode Change" : "Work Mode Change";
			this._userid = this.viewManagementTeamData._id;
			this.meName = this.viewManagementTeamData.meName;
			this.showHideAdd = true;
			this.showHideProfileStatus = false;
		}
		else {
			this.is_edit = true;
			this.showHideAdd = false;
			this.showHideProfileStatus = true;
			if (this.viewManagementTeamData) {
				this.profileMode = "Edit Profile";

				if (this.viewManagementTeamData._typeDrodown == "All") {
					this._userid = this.viewManagementTeamData._element.id
					this.showHideAdd = true;
					this.meName = this.viewManagementTeamData.meName;
				}
				else {
					this._userid = this.viewManagementTeamData._id;
					this.showHideAdd = false;
				}
				let _profileStatusValue = this.viewManagementTeamData._element.profile_status;
				let _profileStatus = _profileStatusValue.replace(/\w\S*/g, function (txt) {
					return txt.charAt(0).toUpperCase()
						+ txt.substr(1).toLowerCase();
				});
				this.previous_email = this.viewManagementTeamData._element.email;
				this.createdForm = this.formbuilder.group({
					name: [this.viewManagementTeamData._element.name],
					email: [this.viewManagementTeamData._element.email],
					countryCode: ['+91'],
					mobileNo: [this.viewManagementTeamData._element.phone.replace(/\D/g, '').slice(-10)],
					role: [this.viewManagementTeamData._element.role],
					reportTo: [this.viewManagementTeamData._element.reportTo],
					profileStatus: [_profileStatus],
					jobProfile: [this.viewManagementTeamData._element.job_profile],
					secExec: [parseInt(this.viewManagementTeamData._element.coordinator)],
					address2: [this.viewManagementTeamData._element.address2 ? this.viewManagementTeamData._element.address2.split(',') : ''],
					work_option: [this.viewManagementTeamData._element.work_option]
				})
			}

		}

	}
	closeDialog() {
		this.dialogRef.close();
	}
	validateEmailUser(_email, _mobileNo, cb) {
		this.currentLoader = false;
		let _obj = {
			"_email": _email,
			"_mobileNo": _mobileNo,
		}
		this.API.validateEmailUserTeamManagement(_obj, this.api_end_point).subscribe((data: any) => {
			/*if (data.groupid && data.groupid > 1) {
				this.passingMessage = "User with this details already part of a team";
				this.showHidePassingMessage = true;
				this._color = 'red';
				cb('User with this details already exists in another group');
				this.currentLoader=false;
				return;
			} else { }*/
			if (data.email && (data.email == _email)) {
				this.passingMessage = _email + " email already exists.";
				this.showHidePassingMessage = true;
				this._color = 'red';
				cb('old_user');
				this.currentLoader = false;
				return;
			} else if (data.phone && (data.phone == _mobileNo)) {
				this.passingMessage = _mobileNo + " number already exists.";
				this.showHidePassingMessage = true;
				this._color = 'red';
				cb('old_user');
				this.currentLoader = false;
				return;
			} else {
				cb(null, 'new user');
				this.currentLoader = false;
			}


		});
	}
	changeFile(event) {
		this.currentFileobj = {};

		if (event.target.files && event.target.files.length) {
			
			//console.log(event.target.files);
			this.currentFileobj = event.target.files[0];
			const [upload_file] = event.target.files;
			this.createdForm.patchValue({
				fileSource: upload_file
			});
			this.showHideSubmitUpload = true;
		}
		else {
			this.showHideSubmitUpload = false;
		}
	}
	saveButton(createdForm: any) {
		debugger;
		//this.currentLoader=true;
		if (this.viewManagementTeamData && this.viewManagementTeamData.upload && createdForm.get('fileSource').value && this.viewManagementTeamData.forNew == "add") {
			let formData = new FormData();
			//this.currentLoader = true;
			let bulk_file = createdForm.get('fileSource').value;
			if (bulk_file) {
				formData.append('team_file', bulk_file, bulk_file.name);

				this.API.createTeamAfterRecruitment(formData, this.api_end_point).subscribe(
					(res: any) => {
						if (res.resolved && res.resolved.length) {
							this.passingMessage = "Data upload successful";
							this.showHidePassingMessage = true;
							this._color = 'green';
							setInterval(() => {
								this.dialogRef.close({ refresh: true });
								this.currentLoader = false;
							}, 2000);
							this.API.teamAPI(this.api_end_point)
								.subscribe(
									(res) => {
										this.service.set_my_team(res);
									});
						} else if (res.unresolved && res.unresolved.length) {
							let _modelObject = res.unresolved;
							_modelObject.list = [];
							var index = 0;
							this.passingMessage = "Please Check Details";
							this.showHidePassingMessage = true;
							this._color = 'red';
							setInterval(() => {
								this.dialogRef.close({ refresh: true });
								this.currentLoader = false;
							}, 2000);
							// for(let obj of res.unresolved){
							// 	if(_modelObject.list.length){
							// 		_modelObject.list[0] = obj;
							// 	}else{
							// 		_modelObject.list.push(obj);

							// 	}

							// 	this.API.updateTeamManagement(_modelObject).subscribe((data: any) => {
							// 		if (data.success) {					
							// 			// alert("User updated successfully");
							// 			if(index == res.unresolved.length){
							// 			this.passingMessage = "User updated successfully..!";
							// 			this.showHidePassingMessage = true;
							// 			this._color = 'green';
							// 			setInterval(() => {
							// 				this.dialogRef.close({ refresh: true });
							// 				this.currentLoader=false;
							// 			}, 2000);
							// 			this.API.teamAPI()
							// 					.subscribe(
							// 					(res) => {
							// 						this.service.set_my_team(res);
							// 					});
							// 			}


							// 		}
							// 	});

							// 	index = index +1;
							// }


						} else {
							this.currentLoader = false;
						}

					}
					, (err) => {
						//	console.log(err)
					}
				)
			}
			return;
		}
		// if (createdForm.controls.name.value == "") {
		// 	this.passingMessage = "Please enter Name.";
		// 	this.showHidePassingMessage = true;
		// 	this._color = 'red';
		// 	return false;
		// }
		// if (createdForm.controls.email.value == "") {
		// 	this.passingMessage = "Please enter Email.";
		// 	this.showHidePassingMessage = true;
		// 	this._color = 'red';
		// 	return false;
		// }
		// if ((createdForm.controls.mobileNo.value).length != 10) {
		// 	this.passingMessage = "Mobile No. should be 10 digit.";
		// 	this.showHidePassingMessage = true;
		// 	this._color = 'red';
		// 	return false;
		// }
		// else {
		// 	this.showHidePassingMessage = false;
		// }
		if (this.viewManagementTeamData.forNew == "add") {
			let _name = createdForm.controls.name.value;
			let _email = createdForm.controls.email.value;
			let _countryCode = createdForm.controls.countryCode.value;
			let _mobileNo = createdForm.controls.mobileNo.value;
			let _role = createdForm.controls.role.value;
			let _reportTo = createdForm.controls.reportTo.value;
			let jobProfile = createdForm.controls.jobProfile.value;
			let secExec = createdForm.controls.secExec.value;
			let address2 = createdForm.controls.address2.value ? createdForm.controls.address2.value.toString() : "";
			let _userid = this._userid;
			let loc = this;
			this.validateEmailUser(_email, _mobileNo, (err, msg) => {
				// console.log('callback received from validate email', err, msg)
				if (err) {
					return;
				}
				// return;
				let _modelObject = {
					"user_id": _userid,
					"list": [{
						"dependenttype": "engineer",
						"request": "invite",
						"status": "invite",
						"email": _email,
						"phone": _mobileNo,
						"name": _name,
						"job_profile": jobProfile,
						"coordinator": secExec,
						"address2": address2,
					}]
				}
				// console.log(_modelObject)
				this.currentLoader = true;
				loc.API.saveTeamManagement(_modelObject, this.api_end_point).subscribe((data: any) => {
					this.currentLoader = true;
					if (data && data.length && data[0].success) {
						// alert("User added successfully");
						loc.passingMessage = "User added successfully..!";
						loc.showHidePassingMessage = true;
						loc._color = 'green';
						setInterval(() => {
							// this.createdForm.reset();
							loc.dialogRef.close({ refresh: true });
							this.currentLoader = false;
						}, 2000);
						loc.API.teamAPI(this.api_end_point)
							.subscribe(
								(res) => {
									loc.service.set_my_team(res);
								});

					}
					if (data.developerMsg) {
						// alert(data.developerMsg.developerMsg.errors);
						loc.passingMessage = data.developerMsg.developerMsg.errors;
						loc.showHidePassingMessage = true;
						loc._color = 'red';
						this.currentLoader = false;
					}
				});
			});
		}
		else if (this.viewManagementTeamData && this.viewManagementTeamData.upload && createdForm.get('fileSource').value && this.viewManagementTeamData.forNew == "workoption"){

			if (this.createdForm.get('fileSource').value == "") {

				this._snackBar.open("Please attached Data file*", "close");
				return;
			}
			let filePath = this.createdForm.get('fileSource').value.name;
			let allowedExtensions = /(\.xlsx)$/i;

			if (!allowedExtensions.exec(filePath)) {
				this._snackBar.open('Please upload .xlsx extensions file only.', 'Close');
				return;
			}
			let formData = new FormData();
			let bulk_file = this.createdForm.get('fileSource').value;

			formData.append('team_list', bulk_file, bulk_file.name);
			this.API.work_mode_Change_bulk(formData,this.api_end_point).subscribe((response:any)=>{
				if (response.success) {
					let ELEMENT_DATA = response.success;
					this._snackBar.open(ELEMENT_DATA, "close");
					setInterval(() => {
						this.dialogRef.close({ refresh: true });
						this.currentLoader = false;
					}, 2000);
				}
				if(response.errors){
					//console.log(response.errors)
					this._snackBar.open(response.errors, "close");
					setInterval(() => {
						this.dialogRef.close({ refresh: true });
						this.currentLoader = false;
					}, 2000);
				}
			})
		}
		else {
			//this.currentLoader = true;

			let _name = createdForm.controls.name.value;
			//let _email = createdForm.controls.email.value;
			let _mobileNo = createdForm.controls.mobileNo.value;
			let _countryCode = createdForm.controls.countryCode.value;
			let _role = createdForm.controls.role.value;
			let _reportTo = createdForm.controls.reportTo.value;
			let jobProfile = createdForm.controls.jobProfile.value;
			let secExec = createdForm.controls.secExec.value;
			let address2 = createdForm.controls.address2.value ? createdForm.controls.address2.value.toString() : "";
			let _modelObject;
			var Object_workmode;
		//	console.log("--"+this.viewManagementTeamData._id+"--"+this.viewManagementTeamData._element.id);
			if (this.previous_email == this.createdForm.controls.email.value) {
				_modelObject = {
					"user_id": this.viewManagementTeamData._id,
					"list": [{
						"dependenttype": "engineer",
						"request": "invite",
						"status": createdForm.controls.profileStatus.value,
						"email": this.createdForm.controls.email.value,
						"to_email": this.createdForm.controls.email.value,
						"phone": _countryCode + _mobileNo,// "+91"+_mobileNo,
						"name": _name,
						"locality": null,
						"city": null,
						"state": address2,
						"country": null,
						"id": this.viewManagementTeamData._element.id,
						"role": _role,
						"job_profile": jobProfile,
						"coordinator": secExec,
						//"address2": address2,
					}]
				}
				Object_workmode = {
					"agent_work_option": this.createdForm.controls.work_option.value,
					"agents_list": [this.viewManagementTeamData._element.id],
					"user_set": this.viewManagementTeamData._element.id
				}
			} else {
				_modelObject = {
					"user_id": this.viewManagementTeamData._id,
					"list": [{
						"dependenttype": "engineer",
						"request": "invite",
						"status": createdForm.controls.profileStatus.value,
						"email": this.previous_email,
						"to_email": this.createdForm.controls.email.value,
						"phone": _countryCode + _mobileNo,// "+91"+_mobileNo,
						"name": _name,
						"locality": null,
						"city": null,
						"state": address2,
						"country": null,
						"id": this.viewManagementTeamData._element.id,
						"role": _role,
						"job_profile": jobProfile,
						"coordinator": secExec,
						//"address2": address2,
					}]
				}
				Object_workmode = {
					"agent_work_option": this.createdForm.controls.work_option.value,
					"agents_list": [this.viewManagementTeamData._element.id],
					"user_set": this.viewManagementTeamData._element.id
				}
			}
			console.log(this.myUser.company);				
			if(this.myUser.company == "SBI-CC" || this.myUser.company == "BANK1-TESTING")
			{
				Object_workmode = {
					"agent_work_option": this.createdForm.controls.work_option.value,
					"agents_list": [this.viewManagementTeamData._element.id],
					"user_set": this.viewManagementTeamData._element.id
				}
				this.API.Work_Mode_Change(Object_workmode, this.api_end_point).subscribe((data: any) => {
					if (data.success) {
						// this.passingMessage = "User updated successfully..!";
						// this.showHidePassingMessage = true;
						// this._color = 'green';
	
						//console.log(_modelObject);
						//return;
						
					}
					if (data.errors) {
						alert(data.errors);
					}
				})
			}
			else{
				this.API.updateTeamManagement(_modelObject, this.api_end_point).subscribe((data: any) => {
					if (data.success) {
						// alert("User updated successfully");
						this.passingMessage = "User updated successfully..!";
						this.showHidePassingMessage = true;
						this._color = 'green';
						setInterval(() => {
							this.dialogRef.close({ refresh: true });
							this.currentLoader = false;
						}, 2000);
						this.API.teamAPI(this.api_end_point)
							.subscribe(
								(res) => {
									this.service.set_my_team(res);
								});
	
					}
					if (data.errors) {
						//  alert(data.errors);
						this.passingMessage = data.errors;
						this.showHidePassingMessage = true;
						this._color = "red";
						this.currentLoader = false;
					}
				});
			}
		}
	}

	deleteUserFromGroup() {
		//	this.currentLoader=true;
		const obj = {
			dependent_id: this.viewManagementTeamData._element.id,
			user_id: this.viewManagementTeamData._id,
		}
		//console.log(obj);
		this.API.getManagmentTeam(this.api_end_point).subscribe((response: any) => {
			if (response.success) {
				let ELEMENT_DATA = response.success;
				//console.log(ELEMENT_DATA);


				var ddlGroup = ELEMENT_DATA.groups;
			//	console.log("all group data" + ddlGroup);
				let _arrayAllData = [];
				for (let i = 0; i < ddlGroup.length; i++) {
				//	console.log(ddlGroup[i]);
				//	console.log(ddlGroup[i].id, "--", obj.dependent_id);
					if (ddlGroup[i].id == obj.dependent_id) {
						//console.log("confirm working");
					//	console.log(ddlGroup[i].groups.length)
						if (ddlGroup[i].groups.length > 0) {
							this._snackBar.open("Before Delete Move agent", "close");
						}
						else {
							this.iddeletion(obj);
						}
					} else {
					//	console.log('printing view element', this.viewManagementTeamData);
						if (this.viewManagementTeamData._element.groups.length > 0) {
							this._snackBar.open("Before Delete Move agent", "close");
						} else {
							this.iddeletion(obj);
						}
						/* var checkouttlgroup = ddlGroup[i].groups;
						for (let j = 0; j < checkouttlgroup.length; j++) {
							if (checkouttlgroup[j].id == obj.dependent_id) {
								
								console.log("else group confirm working");
								console.log(checkouttlgroup[j]);
								console.log(checkouttlgroup[j].groups.length);
								if (checkouttlgroup[j].groups.length > 0) {
									this._snackBar.open("Before Delete Move agent", "close");
								}else{
									this.iddeletion(obj);
								}
							}
						} */
						//debugger;
					}
				}
			}
		})
	}

	iddeletion(datashare) {

		const obj1 = datashare;
		//console.log(obj1);
		this.API.deleteTeamManagement(obj1, this.api_end_point).subscribe((data: any) => {
			if (data.success) {
				// alert("User deleted successfully");
				this.passingMessage = "User deleted successfully..!";
				this.showHidePassingMessage = true;
				this._color = 'green';
				setInterval(() => {
					this.dialogRef.close({ refresh: true });
					this.currentLoader = false;
				}, 2000);

			}
			if (data.errors) {
				//  alert(data.errors);
				this.passingMessage = data.errors;
				this.showHidePassingMessage = true;
				this._color = "red";
				this.currentLoader = false;
			}
		});
	}

	omit_special_char(event) {
		let k;
		k = event.charCode;  //         k = event.keyCode;  (Both can be used)
		return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
	}
	onlyNumberValidation(event) {
		return event.charCode >= 48 && event.charCode <= 57;
	}
	validateEmail() {
		let emailField = this.createdForm.controls.email.value;
		let reg = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

		if (reg.test(emailField) == false) {
			// alert('Invalid Email Address');
			this.passingMessage = "Invalid Email Address";
			this.showHidePassingMessage = true;
			this._color = 'red';
			return false;
		}
		this.showHidePassingMessage = false;
		return true;

	}
	clearField() {
		this.createdForm.get('name').reset();
		this.createdForm.get('email').reset();
		this.createdForm.get('mobileNo').reset();
		this.passingMessage = null;
		this.showHidePassingMessage = false;
		this._color = '';
	}
	validateMobilePhone() {
		let _mob = this.createdForm.controls.mobileNo.value;
		if (_mob.length != 10) {
			this.passingMessage = "Mobile No. should be 10 digit.";
			this.showHidePassingMessage = true;
			this._color = 'red';
			return false;
		}
		else {
			this.showHidePassingMessage = false;
		}
	}

	download_file() {
		if (this.viewManagementTeamData.forNew == "workoption") {
			var url = "/api/v1/attachments/AgentWorkOptionChange.xlsx";
		} else {
			var url = "/api/v1/attachments/Id_Creation.xlsx";
			//console.log("https://dev.youearn.in/api/v1/attachments/Id_Creation.xlsx?access_token=" + this.myUser.access_token);
			//FileSaver.saveAs(url);
		}
		this.API.downloadReportFromUrl(url, true, this.api_end_point).then(() => {
			console.log('file download completed');
		});
		/* let content_type = 'application/octet-stream';
		let file_name = url.split('/').pop()
		//let url = window.URL.createObjectURL(blob);
		var anchor = document.createElement("a");
		anchor.download = file_name;
		anchor.href = url;

		anchor.click(); */
		//debugger;
	}
}


